import { useState, useEffect, useMemo } from "react";
import { Flex, Form } from "antd";
import {
  BasicFilter,
  BasicButton,
  BasicSelectMultiple,
  commonStyles,
} from "@datwyler/mfe-shared-components";

import { transformCountryOption, allowedExtensions } from "@helpers";
import { AttachmentFilterType } from "@types";

const { InputField } = commonStyles;

export const AttachmentFilter = ({
  setFilters,
  locations,
  setPageNumber,
  intl,
}: AttachmentFilterType) => {
  const [submittable, setSubmittable] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { resetFields } = form;
  const locationsValue = Form.useWatch("locations", form);

  // Watch all values
  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const onReset = () => {
    resetFields();
    setFilters({});
    setPageNumber(1);
  };

  const onFinish = ({ filename, siteId, name }) => {
    setFilters({ filename, siteId, name });
    setOpen(false);
  };

  const locationOptions = useMemo(
    () => transformCountryOption(locations),
    [locations]
  );

  const sites = useMemo(() => {
    const locs = (locationsValue || []).map((id) => {
      return locations.find((loc) => loc.id === id);
    });

    const siteList = locs.reduce((accums, current) => {
      return [...accums, ...(current.sites || [])];
    }, []);

    return siteList.map(({ id, name }) => ({ label: name, value: id }));
  }, [locationsValue, locations]);

  const filenameOptions = useMemo(() => {
    return allowedExtensions.map((extension) => ({
      label: extension.split(".").pop(),
      value: extension,
    }));
  }, []);

  const filters = (
    <Form name="filters" form={form} onFinish={onFinish}>
      <Flex vertical gap={16} style={{ width: 354 }}>
        <Form.Item name="name" noStyle>
          <InputField placeholder={intl.formatMessage({ id: "name" })} />
        </Form.Item>
        <Form.Item name="locations" noStyle>
          <BasicSelectMultiple
            placeholder="Select Location"
            options={locationOptions}
          />
        </Form.Item>
        {(locationsValue || []).length > 0 && (
          <Form.Item
            name="siteId"
            rules={[
              {
                required: true,
              },
            ]}
            noStyle
          >
            <BasicSelectMultiple
              placeholder={intl.formatMessage({ id: "site_placeholder" })}
              options={sites}
            />
          </Form.Item>
        )}
        <Form.Item name="filename" noStyle>
          <BasicSelectMultiple
            placeholder={intl.formatMessage({ id: "file_placeholder" })}
            options={filenameOptions}
          />
        </Form.Item>
        <Flex justify="end" gap={16}>
          <Form.Item noStyle>
            <BasicButton onClick={onReset}>
              {intl.formatMessage({ id: "reset_btn" })}
            </BasicButton>
          </Form.Item>
          <Form.Item noStyle>
            <BasicButton
              disabled={!submittable}
              htmlType="submit"
              type={"primary"}
            >
              {intl.formatMessage({ id: "apply_btn" })}
            </BasicButton>
          </Form.Item>
        </Flex>
      </Flex>
    </Form>
  );
  return (
    <>
      <BasicFilter content={filters} open={open} setOpen={setOpen} />
    </>
  );
};

export default AttachmentFilter;
